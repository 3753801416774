<template>
  <div class="contact-data fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>

        <v-container>

          <v-row justify="center" class="mt-0">
            <v-col cols="12" class="mt-0 py-0">
              <v-stepper alt-labels
                         elevation="0">
                <v-stepper-header>
                  <v-stepper-step step="" class="active-step today-medium">
                    Póliza
                  </v-stepper-step>

                  <v-divider inset class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Simulador
                  </v-stepper-step>

                  <v-divider class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Formulario
                  </v-stepper-step>

                  <v-divider class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Firma de documentos
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Pago
                  </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </v-col>
            <v-col class="text-center justify-center d-flex d-sm-none">
              <div class="text-h6 today-medium primary--text">
                Firma de documentos
              </div>
            </v-col>

          </v-row>

          <v-row justify="center" class="px-4">
            <v-col cols="12" md="10" class="">

              <object class="bio-pdf-viewer"
                      :data="pdf+'#view=FitH'"
                      type="application/pdf">
                <embed :src="pdf+'#view=FitH'" type="application/pdf" />
              </object>

            </v-col>

          </v-row>

          <v-row justify="center" class="px-4">
            <v-col cols="12" md="10">
              <p class="mb-0 today-medium primary--text line-height-1">{{ content }}</p>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="10" md="6" lg="5">
              <v-row>
                <v-col v-for="(key, i) in activationKeyFields" cols="2" class="px-2 text-center">
                  <v-text-field
                      type="text"
                      :key="i"
                      :data-length="key.length"
                      :data-index="i"
                      :ref="`input-${i}`"
                      v-model="key.value"
                      v-on:keyup="handleActivationInput($event)"
                      class="px-0 input-field code-input"
                      outlined
                      dense
                      color="primary"
                  maxLength="1"></v-text-field>
                </v-col>

              </v-row>
            </v-col>
          </v-row>

          <p v-if="!reSendCode" class="primary--text today-medium text-center" style="font-size: 0.7em;">Se habilitará un nuevo código en 00:{{ countdown }}</p>

          <p class="primary--text text-center" v-if="reSendCode">
            <a href="#" @click="reSendSignatureOtp()" class="text-center today-regular">Reenviar código</a>
          </p>

          <v-layout justify-center>
            <v-card-actions>
              <v-btn
                  @click="goPayment"
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  class="px-12 text-capitalize vardi-button"
                  elevation="0">
                Continuar
              </v-btn>
            </v-card-actions>
          </v-layout>

        </v-container>
      </v-col>
    </v-row>

    <v-snackbar
        v-model="snackbar"
        :timeout="4000"
        bottom
        right>
      <div v-html="snackbarMessage"></div>
    </v-snackbar>

  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";
import {locals} from "@/helpers/locals";

export default {
  name: 'SignatureDocuments',
  components: {LeftSideImage, NavBarPage},
  data() {
    return {
      codeSent: false,
      expires_in: 120,
      countdown: '',
      settings: null,
      request: null,
      activationKeyFields: [
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' }
      ],
      snackbar: false,
      snackbarMessage: '',
      content: '',
      pdf: '',
      loading: false,
      reSendCode: false
    }
  },
  mounted() {
    this.getSignDocuments();
  },
  methods: {

    toastTest(typeEvent) {
      this.snackbarMessage = typeEvent;
      this.snackbar = true;
    },

    goPayment() {

      let token = '';
      this.activationKeyFields.forEach((value) => {
        token = token + value.value;
      });

      if(token.length == 6) {

        this.loading = true;
        api.post(constants.endPoints.signDocument, {
          user_request_id: this.request.id,
          token: token
        }, true)
          .then(response => {
            this.loading = false;

            let dataResponse = response.data;
            console.log('signDocument', dataResponse);

            this.snackbarMessage = dataResponse.message;
            this.snackbar = true;

            if(dataResponse.status) {
              this.$router.push('/pago');
            }

          }).catch(function (error) {

            this.loading = false;
            console.log('errors: ', error);

            try {
              let status = error.response.status;
              if(status == 401){
                //locals.clearLocal();
                //this.$router.push('/');
              }
              let errors = error.response.data.errors;
              let message = '';
              for (var [key, value] of Object.entries(errors)) {
                console.log(key + ' : ' + value);
                message = message + value + '<br>';
              }
              this.snackbarMessage = message;
              this.snackbar = true;
              this.loading = false;
            } catch (e) {
              console.log(e);

              this.snackbarMessage = e;
              this.snackbar = true;

              //locals.clearLocal();
              //this.$router.push('/');
            }

          }.bind(this))

      } else {
        this.snackbarMessage = 'El token debe tener 6 digitos';
        this.snackbar = true;
      }

    },
    formatCurrency: function(value) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
      return formatter.format(parseInt(value, 10));
    },
    startCountDown: function() {
      this.countdown = this.expires_in;
      this.interval = setInterval(() => {
        if (this.countdown === 0) {
          clearInterval(this.interval);
          this.countdown = this.expires_in;
          this.reSendCode = true;
          //this.reSendSignatureOtp();
        } else {
          this.countdown -= 1;
          this.reSendCode = false;
        }
      }, 1000);
    },
    reSendSignatureOtp() {
      api.post(constants.endPoints.reSendSignatureOtp, {
        user_request_id: this.request.id,
      }, true)
        .then(response => {
          let dataResponse = response.data.data;

          this.snackbarMessage = dataResponse.otp.message;
          this.snackbar = true;

          this.startCountDown();

          console.log('reSendSignatureOtp', dataResponse);

        }).catch(function (error) {
          //
          console.log('errors: ', error);

          try {
            let status = error.response.status;
            if(status == 401){
              locals.clearLocal();
              this.$router.push('/');
            }
            let errors = error.response.data.errors;
            let message = '';
            for (var [key, value] of Object.entries(errors)) {
              console.log(key + ' : ' + value);
              message = message + value + '<br>';
            }
            this.snackbarMessage = message;
            this.snackbar = true;
            this.loading = false;
          } catch (e) {
            console.log(e);
            locals.clearLocal();
            this.$router.push('/');
          }

        }.bind(this))
    },
    goNext(event){
      console.log(this.$refs.digit2)
      this.$refs.digit2.focus()
    },
    handleActivationInput(event) {
      // Grab input's value
      let value = event.target.value;
      // Grab data-index value
      let index = parseInt(event.target.dataset.index);
      // Grab data-length value
      let maxlength = event.target.dataset.length;

      if (this.activationKeyFields[index].value.length > maxlength) {
        event.preventDefault();
        this.activationKeyFields[index].value = value.slice(0, 6);
        try {
          this.$refs[`input-${parseInt(index + 1)}`][0].focus();
        } catch (e) {}
        return;
      }

      // Shift focus to next input field if max length reached
      if (value.length >= maxlength) {
        if (typeof this.activationKeyFields[index + 1] == 'undefined') {
          event.preventDefault();
          return;
        }
        this.$refs[`input-${parseInt(index + 1)}`][0].focus();
        event.preventDefault();
      }
      console.log(event, index, maxlength, this.activationKeyFields);

    },

    getSignDocuments() {
      api.post(constants.endPoints.signDocuments, {
        user_request_id: this.request.id,
      }, true)
          .then(response => {
            let dataResponse = response.data.data;

            this.content = dataResponse.content;
            this.pdf = dataResponse.pdf;

            this.snackbarMessage = dataResponse.otp.message;
            this.snackbar = true;

            this.startCountDown();

            console.log('signDocuments', dataResponse);

          }).catch(function (error) {
        //
        console.log('errors: ', error);

        try {
          let status = error.response.status;
          if(status == 401){
            locals.clearLocal();
            this.$router.push('/');
          }
          let errors = error.response.data.errors;
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            console.log(key + ' : ' + value);
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;
        } catch (e) {
          this.snackbarMessage = e;
          this.snackbar = true;
          console.log(e);
          //locals.clearLocal();
          this.$router.push('/');
        }

      }.bind(this))
    }

  },
  created: function() {
    this.settings = locals.getLocal('settings');
    this.request = locals.getLocal('request');

    this.settings.config.forEach((value) => {
      if(value.key == 'otp_seconds') {
        this.expires_in = value.value;
      }
    });

  },
  computed: {
    activationKey() {
      let value = '';
      for (let field of this.activationKeyFields) {
        value += field.value;
      }
      return value;
    }
  },
  destroyed() {
    clearInterval(this.interval);
  }
}
</script>

<style lang="scss">
.v-stepper__label {
  font-size: 12px;
}

.active-step .v-stepper__label {
  color: #00205B !important;
}

.active-step > span  {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
  background: #00205B !important;
}

.grey-step > span {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin-top: 29px !important;
}

.active-separator-step {
  border-color: #00205B !important;
}

.grey-separator-step {
  border-color: rgba(0, 0, 0, 0.38) !important;
}

.scroll-container {
  height: 450px;
  overflow: scroll;
  overflow-x: hidden;
}

.v-divider {
  border-width: 1px 2px 2px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 29px -82px 0 !important;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (max-width: 600px) {
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 120px !important;
    padding: 5px !important;
  }
  .v-divider {
    visibility: hidden;
  }
}
</style>